<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="8">
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Partner Name <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.mp_name" placeholder="e.g Partner Name">
                      </b-form-input>
                      <VValidate name="Partner Name" v-model="row.mp_name" :rules="mrValidation.mp_name" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Partner Category <span class="text-danger mr5">*</span></label>
                      <v-select id="proCategory" v-model="row.mp_category" :options="mrCategory" label="mpc_category" :reduce="v => +v.mpc_id" />
                      <VValidate name="Partner Category" v-model="row.mp_category" :rules="mrValidation.mp_category" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>NIK</label>
                      <b-form-input v-model="row.mp_nik" type="number" :formatter="$parent.nik" placeholder="e.g 200499828028028"></b-form-input>
                      <VValidate name="NIK" v-model="row.mp_nik" :rules="mrValidation.mp_nik" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Email Address</label>
                      <b-form-input v-model="row.mp_email" placeholder="e.g Email Address"></b-form-input>
                      <VValidate name="Email Address" v-model="row.mp_email" :rules="mrValidation.mp_email" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label>Mobile Number  <span class="text-danger mr5">*</span></label>
                      <b-form-input v-model="row.mp_phone" type="number" :formatter="$parent.telp" placeholder="e.g Mobile Number"></b-form-input>
                      <VValidate name="Mobile Number" v-model="row.mp_phone" :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="8">
                    <b-form-group>
                      <label>Workshop Address </label>
                      <b-form-textarea
                        id="textarea"
                        v-model="row.mp_address"
                        placeholder="Enter Address..."
                        rows="4"
                        max-rows="4"
                      ></b-form-textarea>
                      <VValidate name="Workshop Address" v-model="row.mp_address" :rules="mrValidation.mp_address" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group class="">
                      <label>Status<span class="text-danger mr5">*</span></label>
                      <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row[$parent.statusKey]" />
                      <VValidate name="Status" v-model="row.mp_address" :rules="mrValidation.mp_status" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="4">
                <div class="file-uploader">
                  <label>Photo</label>
                  <Uploader v-model="row.mp_image" type="partner"/>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="info" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
 export default {
    props: {
      row: Object,
      mrValidation: Object,
      Config: Object,
      mrCategory: Array
    },
    methods: {
      doSubmitForm() {
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
    }
 }
</script>
